import React from 'react'
export const messageList =[
    {   
        id:1,
        username: "bot",
        content:' Hey! Hope all is well at your end. ',
        buttonText:' Hey! Hope all is well at your end. ',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    }, 
    {   
        id:2,
        username: "bot",
        content: <p>We, <b>Sodhani’s & Kabra’s</b> have a great news to share with you! </p>,
        buttonText: <p>We, <b>Sodhani’s & Kabra’s</b> have a great news to share with you! </p>,
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    }, 
    {   
        id:3,
        username: "bot",
        content: <p><b>Tap below</b> to know more!👇🏼</p>,
        buttonText: <p><b>Tap below</b> to know more!👇🏼</p>,
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    }, 
    {
        id:4,
        username: "user",
        content: 'So what’s in store?',
        buttonText: 'So what’s in store?',
        type:'text',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        display:false,
    }, 
    {
        id:5,
        username: "bot",
        content: '',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        image:require('../images/bigFatWedding.jpg'),
        // image:'http://www.haringphotography.com/wp-content/uploads/2018/08/Florida-Best-indian-wedding-photographer-4.jpg',
        display:false,
    }, 
        {
        id:6,
        username: "bot",
        content: <p><b>Aditya & Sneha</b> are tying the knot!</p> ,
        buttonText: <p><b>Aditya & Sneha</b> are tying the knot!</p> ,
        img: "http://i.imgur.com/ARbQZix.jpg",
        type:'text',

        display:false,
    }, 
    {
        id:7,
        username: "bot",
        content: <p>And you’re invited for the <b>BIG FAT INDIAN WEDDING!</b></p>,
        buttonText: <p>And you’re invited for the <b>BIG FAT INDIAN WEDDING!</b></p>,
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    }, 
    {
        id:8,
        username: "user",
        content: ' Congratulations! 💃💃  What are the dates?',
        buttonText: ' Congratulations! 💃💃  What are the dates?',
        type:'text',
        display:false,
    }, 
        {
            id:9,
            username: "bot",
            content: '19th - 21st of April 2019',
            buttonText: '19th - 21st of April 2019',
            img: "http://i.imgur.com/Tj5DGiO.jpg",
            type:'text',
            display:false,
        },
        {
        id:10,
        username: "user",
        content: 'Awesome! Where are the events?',
        buttonText: 'Awesome! Where are the events?',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
        {
        id:11,
        username: "bot",
        content: 'It’s a destination wedding, held at Hotel Fountain, Mahabaleshwar',
        buttonText: 'It’s a destination wedding, held in Mahabaleshwar',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:12,
        username: "bot",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'map',
        display:false,
    }, 
    {
        id:13,
        username: "user",
        content: ' That’s awesome! Excited for a destination wedding 😃',
        buttonText: 'That’s awesome!😃',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:14,
        username: "bot",
        content: 'On 19th April,',
        buttonText: 'On 19th April, we have Faag Utsav, at 6 PM',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:15,
        username: "bot",
        content: 'We have Faag Utsav, Phoolon ki Holi at 6 PM',
        buttonText: 'On 19th April, we have Faag Utsav, at 6 PM',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:16,
        username: "user",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        content:'Cool',
        buttonText:'Cool',
        type:'text',
        display:false,
    }, 
    {
        id:17,
        username: "bot",
        content: 'On 20th April,',
        buttonText: 'On 20th April,',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:18,
        username: "bot",
        content: 
        <p>We have Mahera in the afternoon,<br></br>
        - Kabra family at <strong>11am</strong>,<br/> 
        - Sodhani family at <strong>2pm</strong>
        </p>,
        buttonText: 'We have Mahera in the afternoon.',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:19,
        username: "bot",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        // image:'https://scontent.fbom19-1.fna.fbcdn.net/v/t31.0-8/26756734_10155893614176142_5426006532846277824_o.jpg?_nc_cat=108&_nc_ht=scontent.fbom19-1.fna&oh=3ae7dfd59a8061f4daa18ae485dc3e96&oe=5D26F719',
        type:'text',
        content:'Followed by Engagement and Sangeet in the evening..',
        display:false,
    },  
    {
        id:20,
        username: "bot",
        content: '& ofcourse DJ Party post that! 😉 ',
        buttonText: 'And Engagement and Sangeet in the evening.',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:21,
        username: "bot",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        image:require('../images/SANGEET.gif'),
        type:'image',
        display:false,
    },
    {
        id:22,
        username: "user",
        content:'Sounds good!',
        buttonText:'Sounds good!',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    }, 
    {
        id:23,
        username: "bot",
        content: 'On 21st April, we have pool party in the morning,',
        buttonText: 'On 21st April, we have pool party in the morning',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:24,
        username: "bot",
        image:require('../images/poolParty.gif'),
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        display:false,
    },
    {
        id:25,
        username: "bot",
        content: 'Nikasi in the afternoon,',
        buttonText: 'Nikasi in the afternoon,',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:26,
        username: "bot",
        image:require('../images/Nikasi.gif'),
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        display:false,
    },
    {
        id:27,
        username: "bot",
        content: '& Pheras in the evening ❤️❤️',
        buttonText: 'Pheras in the evening.',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:28,
        username: "bot",
        image:require('../images/Phera.gif'),
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        display:false,
    },
    {
        id:29,
        username: "user",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        content:' Wow! Sounds awesome! ',
        buttonText:' Wow! Sounds awesome! ',
        display:false,
    },
    {
        id:30,
        username: "user",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        image:require('../images/awesome.gif'),
        type:'image',
        display:false,
    },
    {
        id:31,
        username: "bot",
        content: <p>We are so excited to see you for our wedding. Hope to see you there!</p>,
        buttonText: <p>Get set for<b> #AdiSnehDaVyaah</b>, we’ll see you there !</p>,
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:32,
        username: "bot",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        image:require('../images/invite.jpg'),
        display:false,
    },
    {
        id:33,
        username: "user",
        content: "Surely, we'll let you know..",
        buttonText: "Surely, we'll let you know..",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:34,
        username: "bot",
        content: <p>Kindly RSVP with your name at the link below please?<br/> <br/>
                 <p> <a style={{display: "table-cell"}} href="https://goo.gl/forms/LqvH9AxNzwMMAwTL2" target="_blank">RSVP link</a></p></p>            
        ,
        buttonText: 'Oh and RSVP with your name at the link below please?',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:35,
        username: "bot",
        content: <p>And do not forget to use <strong>#AdiSnehDaVyaah</strong> while posting selfies on social media.</p>,
        buttonText: 'Thank you! So excited to see you there!',
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'text',
        display:false,
    },
    {
        id:31,
        username: "bot",
        img: "http://i.imgur.com/Tj5DGiO.jpg",
        type:'image',
        image:require('../images/selfies.gif'),
        display:false,
    },
    // {
    //     id:36,
    //     username: "bot",
    //     content: <p><a style={{display: "table-cell"}} href="#" target="_blank">text</a></p>,
    //     buttonText: 'Thank you! So excited to see you there!',
    //     img: "http://i.imgur.com/Tj5DGiO.jpg",
    //     type:'text',
    //     display:false,
    // },
   
];
